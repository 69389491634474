<script>
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'ProfileCard',
  props: {
    profile: {
      type: Object,
      required
    }
  },
  computed: {
    mainSkills () {
      return this.profile.skills
        .filter(skill => this.profile.type === 'business' || !!skill.isMain)
        .map(skill => {
          const title = skill.title.length > 25 ? skill.title.slice(0, 25) + '...' : skill.title
          return { ...skill, title }
        })
    },
    coverImage () {
      if (this.profile.type === 'individual') {
        return this.changeThumbToSize(this.profile.imageUrl, 'small') || this.defaultAvatarUrl()
      }
      return this.changeThumbToSize(this.profile.coverImageUrl, 'small') || '/assets/images/workspace-cover-image-default.png'
    }
  },
  methods: {
    goToUserProfile () {
      this.$emit('interaction')
      this.$router.push({ name: this.selectedWorkspace.type + '.workspace.public', params: { id: this.profile.id } })
    }
  }
}
</script>
<template>
  <v-card max-height="380" elevation="8" class="profile-card--container" @click="goToUserProfile">
      <v-img
        height="380px"
        :src="coverImage"
        class="profile-card--image"
      ></v-img>
    <div class="profile-card--body">
      <div class="profile-card--avatar-image" v-if="profile.type === 'business'">
        <v-avatar size="80">
          <v-img :src="profile.imageUrl || defaultAvatarUrl(profile.type === 'business' ? 'business' : 'profile')" />
        </v-avatar>
      </div>
      <h5>{{ profile.name }} {{profile.lastName}}</h5>
      <p class="body--1">{{ profile.position }}</p>
      <div class="profile-card--skills-container">
        <v-chip
          v-for="skill in mainSkills"
          :key="skill.id"
          class="profile-card--skill-chip body--1"
          :dark="skill.level !== 'interest'"
        >
          {{ skill.title }}
        </v-chip>
      </div>
      <v-btn
        small
        text
        color="#ffffff"
        class="see-more-btn btn"
      >
        {{ $t('global:see.more') }} <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
  </v-card>
</template>
<style lang="scss">
.profile-card--container {
  width: 280px;
  .profile-card--image {
    border-radius: 4px;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(to bottom, rgba(0, 0, 0, .2), rgba(0, 0, 0, .75));
    }
  }
  .profile-card--body {
    padding: 0 20px;
    .profile-card--avatar-image {
      margin-bottom: 20px;
    }
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 5;
    h5 {
      color: $neutral-white;
      text-align: center;
    }
    p {
      text-align: center;
      color: $neutral-white;
      font-size: $font-size-normal;
      opacity: .7;
    }
    .profile-card--skills-container {
      text-align: left;
      margin-bottom: 10px;
      .profile-card--skill-chip {
        margin-bottom: 5px;
        margin-right: 5px;
        background-color: $secondary-medium;
        color: $neutral-black;
      }
    }
  }
}
</style>
