<script>
import { required, minLength, maxLength, helpers } from 'vuelidate/lib/validators'
import InputField from '@/components/general/InputField'
import ImageUpload from '@/components/general/ImageUpload'
import SelectField from '@/components/general/SelectField'
import TextArea from '@/components/general/TextArea'
export default {
  name: 'ModalManageList',
  components: {
    TextArea,
    InputField,
    ImageUpload,
    SelectField
  },
  props: {
    instanced: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      formData: {
        coverImage: null,
        title: null,
        description: null,
        languageAlias: null,
        removeImage: null,
        workspaceId: null,
        isPrivate: false
      },
      isSaving: false,
      availableProfiles: null,
      publishToBusiness: false,
      selectedList: null
    }
  },
  validations: {
    formData: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(255)
      },
      coverImage: {
        fileSize: helpers.withParams({
          size: 20971520
        },
        function (image) {
          if (!image || (this.selectedList && image === this.selectedList.coverImageURL)) return true
          return this.isValidBlobSize(image, 20)
        })
      }
    }
  },
  computed: {
    userInfo () {
      return this.$store.getters.getUser
    },
    paramId () {
      return this.$route.params.listId
    },
    publishToIndividual () {
      return !!(this.userWorkspaces && this.userWorkspaces.find(wk => wk.type === 'individual' && wk.id === this.formData.workspaceId))
    },
    userWorkspaces () {
      return this.$store.getters.getUserWorkspaces
    }
  },
  methods: {
    changeImage (image) {
      this.$v.formData.coverImage.$touch()
      this.formData.coverImage = image
      this.formData.removeImage = null
    },
    close () {
      this.$v.$reset()
      !this.instanced && this.$router.push(this.getBackRoute())
      this.instanced && this.$emit('close')
    },
    updateLearningInProgress () {
      const availableStatus = ['conclusion', 'in_progress']
      availableStatus.map(status => {
        const params = {
          filter: { status },
          userId: this.$store.getters.getUser.userId
        }
        this.$store.dispatch('attemptGetFilteredUserLists', params)
        this.$store.dispatch('attemptGetFilteredUserContents', params)
      })
    },
    removeImage () {
      this.formData.coverImage = null
      this.formData.removeImage = true
    },
    save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.isSaving = true
        this.$store.dispatch('attemptSaveList', { data: { ...this.formData, isPrivate: this.publishToIndividual ? this.formData.isPrivate : !this.formData.isPrivate }, id: this.paramId })
          .then((newList) => {
            this.$store.dispatch('attemptSetFeedbackMsg', {
              type: 'success',
              title: this.$t(`list.modal:toast.success.${this.paramId ? 'change' : 'created'}.header`),
              description: this.$t(`list.modal:toast.success.${this.paramId ? 'change' : 'created'}.body`)
            })
            this.updateLearningInProgress()
            this.$store.dispatch('attemptGetMyselfLists', this.$store.getters.getCurrentListFilters)
            this.$store.dispatch('attemptGetWorkspaceLists', { workspaceId: this.selectedWorkspace.lxID })
            this.$emit('onCreated', newList)
          })
          .catch(() => {
            this.$store.dispatch('attemptSetFeedbackMsg', {
              type: 'error',
              title: this.$t('list.modal:toast.error')
            })
          })
          .finally(() => {
            this.formData = {
              coverImage: null,
              title: null,
              description: null,
              languageAlias: null,
              removeImage: null,
              workspaceId: null,
              isPrivate: false
            }
            this.close()
            this.isSaving = false
          })
      }
    },
    getList () {
      return new Promise((resolve, reject) => {
        const currentList = this.$store.getters.getUserLists.find(list => list.id === this.paramId)
        if (!currentList) {
          this.$store.dispatch('attemptGetListById', this.paramId)
            .then(({ data }) => {
              resolve(data)
            })
            .catch((err) => {
              reject(err)
            })
        } else {
          resolve(currentList)
        }
      })
    },
    hydrateFormData () {
      this.getList().then((currentList) => {
        this.selectedList = currentList
        this.formData.workspaceId = currentList.workspaceId
        this.formData.title = currentList.title
        this.formData.description = currentList.description || null
        this.formData.coverImageURL = currentList.coverImageURL
        this.formData.isPrivate = this.publishToIndividual ? currentList.isPrivate : !currentList.isPrivate
      })
    }
  },
  created () {
    this.availableProfiles = this.userWorkspaces
      .filter(workspace => this.userCanManage(workspace.id) && workspace.role !== 'member')
      .map(({ name, type, id, lxID }) => {
        return { text: name, value: lxID, type }
      })
    if (this.paramId) {
      this.hydrateFormData()
    }
    if (this.userManagesContent(this.selectedWorkspace.id)) {
      this.formData.workspaceId = this.selectedWorkspace.lxID
    }
    this.formData.languageAlias = this.userInfo.locale || 'pt-BR'
  }
}
</script>

<template>
  <v-dialog :value="show" width="600" persistent>
    <v-card class="modal-content--container--list">
        <div class="manage-content--header">
          <h3 class="manage-content-title">
            {{ $t('content.modal:add.list') }}
          </h3>
          <v-btn icon @click="close" color="#1200D3"><v-icon>mdi-close</v-icon></v-btn>
        </div>
        <div class="modal-content--content--list">
          <p class="text-left bold manage-content-info">
            {{ $t('workspace.setup.step.4:.profile.image.title') }}
          </p>
          <image-upload :width="560" defaultImageBackground="/assets/images/background-course.png"
            @updateImage="changeImage" :imageBackground="formData.coverImageURL || ''" @removeImage="removeImage"
            :validation="$v.formData.coverImage" :hide-validation="!$v.formData.coverImage.$error && isMobile" />
          <p class="text-left bold manage-content-info mt-0">
            {{ $t('global:details') }}
          </p>
          <input-field outlined v-model="formData.title" :placeholder="$t('list.label:title')"
            :validation="$v.formData.title" :hide-validation="!$v.formData.title.$error && isMobile" />
          <text-area outlined v-model="formData.description" :placeholder="$t('list.label:description')"
            :hide-validation="isMobile" />
          <p class="text-left manage-content-info mb-n0">
            {{ $t('global:publication') }}
          </p>
          <select-field v-if="availableProfiles" :small="isMobile" outlined :placeholder="$t('content:publish.profile')"
            v-model="formData.workspaceId" :items="availableProfiles" />
          <div class="manage-content--privacy__wrapper">
            <v-switch v-model="formData.isPrivate" />
            <div class="manage-content--privacy__text">
              <template v-if="publishToIndividual">
                <p class="text-left pt-2 is-private-label"><b>{{ $t('list:private') }}</b></p>
                <p class="text-left is-private-desc">{{ $t('list:private.warning') }}</p>
              </template>
              <template v-else>
                <p class="text-left pt-2 is-private-label"><b>{{ $t('content:publish.public') }}</b></p>
                <p class="text-left is-private-desc">{{ $t('list:discovery.warning') }}</p>
              </template>
            </div>
          </div>
          <div class="card-content-actions">
            <v-btn class="btn transform-unset" @click="close" text color="#1200D3">
              <b>{{ $t('global:cancel') }}</b>
            </v-btn>
            <v-btn class="btn transform-unset" :loading="isSaving" @click="save" color="#1200D3" dark>
              <b>{{ $t(`${paramId ? 'global:save' : 'global:add'}`) }}</b>
            </v-btn>
          </div>
        </div>

    </v-card>
  </v-dialog>
</template>

<style lang="scss">
.modal-content--container--list {
  position: relative;
  background-color: aqua;

  .modal-content--content--list {
    padding: 0 24px;
    max-height: calc(100vh - 200px);
    overflow-y: scroll;
    padding-bottom: 80px;
  }

  .v-autocomplete:not(.v-input--is-focused).v-select--chips input {
    max-height: 25px !important;
  }

  .is-private-label {
    font-size: 14px;
    font-family: $lato;
    font-weight: bold;
    color: $neutral-black;
  }

  .is-private-desc {
    font-size: 14px;
    font-family: $lato;
    color: $neutral-dark;
    margin-top: -15px;
    width: 100%;
  }

  .card-content-actions {
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 0 24px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 68px;
    background: #FAFAFA;
    border-top: 1px solid rgba(0, 0, 0, 0.06);

    .v-btn:nth-child(1) {
      margin-right: 8px;
    }
  }

  .manage-content--header {
    display: flex;
    justify-content: space-between;
    padding: 24px 24px 16px 24px;
    background-color: #FAFAFA;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    h3 {
      font-size: 16px;
      line-height: 20px;
      color: #000000;
    }
    .manage-content-title {
      font-family: $lato;
      font-size: 20px;
      font-weight: bold;
    }
  }

  .manage-content-info {
    font-family: $lato;
    font-size: 16px;
    font-weight: bold;
    margin-top: 24px;
    color: $neutral-black;
  }

  .manage-content--privacy__wrapper {
    display: flex;

    .v-input--selection-controls {
      margin-top: 4px;
    }
  }

  @media only screen and (max-width: 768px) {
    .card-content-actions {
      margin-right: 0;
    }
  }
}
</style>
