<script>
import InputField from '@/components/general/InputField'
export default {
  name: 'AdvancedSearchMobile',
  components: {
    InputField
  },
  props: {
    value: Object
  },
  data () {
    return {
      formData: {
        textualSearch: ''
      }
    }
  },
  computed: {
    hasFilterApplied () {
      return Object.keys(this.value).length
    }
  },
  watch: {
    'value.textualSearch' () {
      this.formatIncomeData()
    }
  },
  methods: {
    formatIncomeData () {
      this.formData.textualSearch = this.value.textualSearch || null
    },
    cleanupFilter () {
      const cleanFilter = { ...this.value }

      if (this.formData.textualSearch) {
        cleanFilter.textualSearch = this.formData.textualSearch
      } else {
        delete cleanFilter.textualSearch
      }

      return cleanFilter
    },
    search () {
      this.$emit('input', this.cleanupFilter())
      this.$emit('search', this.cleanupFilter())
    }
  },
  created () {
    this.formatIncomeData()
    this.search()
  }
}
</script>
<template>
  <v-card class="advanced-search--container-mobile" :class="{'has-filters': hasFilterApplied}">
    <input-field
      v-model="formData.textualSearch"
      class="advanced-search--text-search"
      outlined
      :placeholder="$t('advanced.search:text.search.placeholder')"
      append-icon="mdi-magnify"
      @click:append="search"
      @keydown.enter="search"
      hideValidation
      clearable
    ></input-field>
    <v-btn class="open-filter--btn" color="#1200D3" :outlined="!hasFilterApplied" :text="!hasFilterApplied" @click="$emit('toggleFilter')"><v-icon>mdi-filter-variant</v-icon>{{ hasFilterApplied ? $t('advanced.search:mobile.search.btn.filled', { num: hasFilterApplied }) : $t('advanced.search:header.title') }}</v-btn>
  </v-card>
</template>
<style lang="scss">
.advanced-search--container-mobile {
  padding: 20px 16px;
  max-width: 100%;
  margin: 0 auto 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .advanced-search--text-search {
    width: 100%;
    .v-input__slot,
    .v-input__control,
    .v-input {
      height: 40px;
      min-height: 40px;
      &.v-input--is-focused {
        .v-input__append-inner:nth-last-child(1) {
          .v-icon {
            color: $neutral-white !important;
          }
        }
      }
    }
    .v-input__append-inner {
      margin-top: 8px;
      padding-right: 4px;
    }
    .v-input__append-inner:nth-last-child(1) {
      background: $primary-medium;
      margin: 0 -12px 0 0;
      border-radius: 0 4px 4px 0;
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      .v-icon {
        color: $neutral-white;
      }
    }
  }
  .v-btn.open-filter--btn {
    @extend .bolder;
    color: $neutral-white;
    min-width: 48px;
    padding: 0 12px;
    &.v-btn--text {
      border-color: $primary-medium;
    }
    .v-icon {
      margin-right: 8px;
    }
  }
}
</style>
