<script>
export default {
  name: 'Pagination',
  props: {
    pagination: {
      type: Object,
      default: () => {
        return { page: 1, totalPages: 1 }
      }
    }
  }
}
</script>
<template>
  <div class="pagination--container">
    <v-btn
      class="pagination--button-first v-btn--is-elevated"
      :class="{'disabled': pagination.page === 1 }"
      :disabled="pagination.page === 1"
      @click="pagination.page = 1"
    >
      <v-icon>mdi-page-first</v-icon>
    </v-btn>
    <v-pagination
      v-model="pagination.page"
      :length="pagination.totalPages"
      :total-visible="isMobile ? 5 : 7"
      @input="() => this.smoothScrollTop()"
    ></v-pagination>
    <v-btn
      class="pagination--button-last v-btn--is-elevated"
      :class="{'disabled': pagination.page === pagination.totalPages }"
      :disabled="pagination.page === pagination.totalPages"
      @click="pagination.page = pagination.totalPages"
    >
      <v-icon>mdi-page-last</v-icon>
    </v-btn>
  </div>
</template>
<style lang="scss">
.pagination--container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  .theme--light.v-pagination .v-pagination__item--active {
    background-color: $primary-medium !important;
    border-color: $primary-medium !important;
  }
  .v-btn.pagination--button-last,
  .v-btn.pagination--button-first {
    padding: 0;
    width: 32px;
    height: 32px;
    min-width: 32px;
    color: rgba(0, 0, 0, .54);
    background: #ffffff;
    &.v-btn.v-btn--disabled {
      opacity: 0.6;
      background-color: #ffffff !important;
      .v-icon {
        color: rgba(0, 0, 0, .54) !important;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .v-pagination {
      .v-pagination__item {
        display: none;
        &.v-pagination__item--active {
          display: block;
        }
      }
    }
  }
}
</style>
