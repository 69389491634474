<script>
import { required } from 'vuelidate/lib/validators'
import LearningCardContent from '@/components/general/LearningCardContent'
import LearningCardList from '@/components/general/LearningCardList'
import ProfileCard from '@/components/general/ProfileCard'
import EmptyList from '@/components/general/EmptyList.vue'
export default {
  name: 'DiscoveryList',
  components: {
    ProfileCard,
    LearningCardList,
    LearningCardContent,
    EmptyList
  },
  data () {
    return {
      loading: true,
      isCreated: false,
      data: []
    }
  },
  props: {
    list: {
      type: Array,
      required
    }
  },
  methods: {
    showModalAddListContent (params) {
      this.$emit('showModalAddListContent', params)
    },
    showModalContentDetails (params) {
      this.$emit('showModalContentDetails', params)
    },
    showModalListDetails (params) {
      this.$emit('showModalListDetails', params)
    },
    hitContent (id) {
      this.$store.dispatch('attemptSubmitHitContent', id)
    },
    hitList (id) {
      this.$store.dispatch('attemptSubmitHitList', id)
    },
    hitProfile (id) {
      this.$store.dispatch('attemptSetAcessContent', true)
      this.$store.dispatch('attemptSubmitHitProfile', id)
    }
  },
  created () {
    this.loading = true
    setTimeout(() => {
      this.loading = false
    }, 2000)
  },
  watch: {
    list () {
      if (this.isCreated) {
        this.loading = true
        setTimeout(() => {
          this.loading = false
        }, 500)
      }
      this.isCreated = true
    }
  }
}
</script>
<template>
  <div class="discovery-list--container" :class="{ 'is-mobile': isMobile }">
    <template v-if="loading">
      <v-skeleton-loader v-for="i in [1, 2, 3, 4]" :key="i" type="card-avatar, article, actions" max-width="340px"
        height="380px" />
    </template>
    <template v-else>
      <template v-if="list.length">
        <div class="discovery-list--item" v-for="(item, index) in list" :key="index">
          <learning-card-content v-if="item.type === 'content'" :content="item.data" originWindow=".discovery"
            @interaction="hitContent(item.data.id)"
            @showModalAddListContent="params => showModalAddListContent(params)"
            @showModalContentDetails="params => showModalContentDetails(params)"></learning-card-content>
          <learning-card-list v-if="item.type === 'list'" :list="item.data" showItemsProgress originWindow=".discovery"
          @showModalListDetails="params => showModalListDetails(params)"
            @interaction="hitList(item.data.id)"></learning-card-list>
          <profile-card v-if="['profile', 'business'].includes(item.type)" :profile="item.data"
            @interaction="hitProfile(item.data.id)"></profile-card>
        </div>
      </template>
      <div class="margin-empty" v-else>
        <empty-list class="margin-empty" :title="$t('empty.state.title:empty.title')"
          :image="'/assets/images/empty-box.png'"></empty-list>
      </div>
    </template>
  </div>
</template>
<style lang="scss">
.discovery-list--container {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  margin-bottom: 40px;

  .margin-empty {
    width: calc(100vw - 400px);
  }

  .discovery-list--item {
    display: inline-block;
    padding: 0;

    .learning-card--container {
      margin: 0;
      width: 100%;
    }
  }

  &.is-mobile {
    justify-content: center;
  }

  .empty-block {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));

    .skeleton {
      display: inline-block;
    }

    .empty-img {
      margin: 0 auto;
      margin-top: 45px;
      margin-bottom: 30px;
    }

    .empty-text {
      font-family: $lato;
      font-size: $font-size-normal;
      margin-top: -20px;
      color: $neutral-medium;
    }
  }

  &.is-mobile {
    justify-content: center;
  }
}
</style>
