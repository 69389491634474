<script>
import InputField from '@/components/general/InputField'
import Accordion from '@/components/general/Accordion'
import AccordionItem from '@/components/general/AccordionItem'
import Autocomplete from '@/components/general/Autocomplete'
import SelectField from '@/components/general/SelectField'
export default {
  name: 'AdvancedSearch',
  components: {
    Autocomplete,
    Accordion,
    AccordionItem,
    InputField,
    SelectField
  },
  data () {
    return {
      searchSkill: '',
      mounted: false,
      openPanels: [0, 3, 6, 9],
      profileLocationSearch: '',
      profileLocationOptions: [],
      businessLocationSearch: '',
      businessLocationOptions: [],
      availableCategories: [
        { text: this.$t('global:contents'), value: 'content' },
        { text: this.$t('global:lists'), value: 'list' }
      ],
      durationType: [
        { text: this.$tc('global:minutes', 0), value: 'minutes' },
        { text: this.$tc('global:hours', 0), value: 'hours' },
        { text: this.$tc('global:days', 0), value: 'days' }
      ],
      formData: this.emptyFormDataState,
      preventSearch: true
    }
  },
  props: {
    value: Object,
    shouldCaching: Boolean
  },
  watch: {
    searchSkill () {
      this.$store.dispatch('attemptGetAvailableSkills', { limit: 99999 })
    },
    'formData.shouldWatch': {
      deep: true,
      handler () {
        if (this.isMobile) return
        !this.preventSearch && this.debounceEvent(() => this.search(), 500)
      }
    },
    value: {
      handler () {
        this.preventSearch = true
        this.$nextTick(() => {
          this.formatIncomeData()
        })
      }
    },
    profileLocationSearch (input) {
      if (!input || input.length < 3) return
      this.debounceEvent(async () => {
        const options = await this.$store.dispatch('attemptGetProfileLocations', input)
        this.profileLocationOptions = options.map(item => ({ text: item.location }))
      }, 900)
    },
    businessLocationSearch (input) {
      if (!input || input.length < 3) return
      this.debounceEvent(async () => {
        const options = await this.$store.dispatch('attemptGetBusinessLocations', input)
        this.businessLocationOptions = options.map(item => ({ text: item.location }))
      }, 900)
    }
  },
  computed: {
    contentTypes () {
      return this.$store.getters.getContentTypes
    },
    availableSkills () {
      return this.$store.getters.getAvailableSkills
        .concat(this.formData.shouldWatch.skills)
        .filter((skill, index, arr) => arr.findIndex(s => s.id === skill.id) === index)
    },
    availableSectors () {
      return this.enumActivitySector.map(sector => {
        return {
          text: this.$t(`activity.sector.default:${sector.toLowerCase()}`),
          value: sector
        }
      })
    },
    emptyFormDataState () {
      return {
        shouldWatch: {
          categories: [],
          skills: [],
          contentTypes: [],
          levels: [],
          languages: [],
          location: null,
          businessLocation: null,
          activitiesSectors: [],
          position: null,
          company: null
        },
        textualSearch: null,
        duration: {
          from: null,
          to: null,
          type: 'minutes'
        }
      }
    }
  },
  methods: {
    formatIncomeData () {
      this.$set(this.formData.shouldWatch, 'categories', this.value.categories || [])
      this.$set(this.formData.shouldWatch, 'skills', this.value.skills || [])
      this.$set(this.formData.shouldWatch, 'contentTypes', this.value.contentTypes || [])
      this.$set(this.formData.shouldWatch, 'levels', this.value.levels || [])
      this.$set(this.formData.shouldWatch, 'languages', this.value.languages || [])
      this.$set(this.formData.shouldWatch, 'activitiesSectors', this.value.activitiesSectors || [])
      this.formData.textualSearch = this.value.textualSearch || null
      this.formData.duration.from = (this.value.duration && this.value.duration.from) || null
      this.formData.duration.to = (this.value.duration && this.value.duration.to) || null
      this.formData.duration.type = (this.value.duration && this.value.duration.type) || 'minutes'
      this.formData.shouldWatch.location = this.value.location || null
      this.formData.shouldWatch.businessLocation = this.value.businessLocation || null
      this.formData.shouldWatch.position = this.value.position || null
      this.formData.shouldWatch.company = this.value.company || null
      this.$nextTick(() => {
        this.preventSearch = false
      })
    },
    cleanupFilter () {
      const cleanFilter = {}
      Object.keys(this.formData).map(key => {
        if (['shouldWatch', 'duration'].includes(key)) return
        if (this.formData[key] && this.formData[key].length) {
          cleanFilter[key] = this.formData[key]
        }
      })
      Object.keys(this.formData.shouldWatch).map(key => {
        if (this.formData.shouldWatch[key] && this.formData.shouldWatch[key].length) {
          cleanFilter[key] = this.formData.shouldWatch[key]
        }
      })

      if (this.formData.duration.to || this.formData.duration.from) {
        cleanFilter.duration = JSON.parse(JSON.stringify(this.formData.duration))
      }

      return cleanFilter
    },
    search (preventSearch = false) {
      this.$emit('input', this.cleanupFilter())
      !preventSearch && this.$emit('search', this.cleanupFilter())
      !preventSearch && this.close()
    },
    clearFilter (shouldClose = true) {
      this.$emit('input', {})
      this.$emit('search', {})
      if (shouldClose) this.close()
    },
    close () {
      this.$emit('close')
    },
    clearTextualSearch () {
      this.formData.textualSearch = null
      this.search(false)
    }
  },
  created () {
    this.$store.dispatch('attemptGetContentTypes')
    if (this.$store.getters.getShouldCachingFilters) {
      this.formData = { ...this.emptyFormDataState, ...this.$store.getters.getAppliedAdvancedFilters }
      if (this.formData.shouldWatch.location) {
        this.profileLocationOptions = [{ text: this.formData.shouldWatch.location }]
      }
      if (this.formData.shouldWatch.businessLocation) {
        this.businessLocationOptions = [{ text: this.formData.shouldWatch.businessLocation }]
      }
      this.search(true)
    } else {
      this.clearFilter(false)
      this.formData = { ...this.emptyFormDataState }
      this.$store.getters.getIndividualWorkspace.languages && this.$store.getters.getIndividualWorkspace.languages.forEach(preferredLang => {
        this.formData.shouldWatch.languages.push(preferredLang.alias)
      })
      this.search(this.preventSearch)
    }
  },
  mounted () {
    this.mounted = true
    if (this.formData.shouldWatch.languages.length) {
      this.openPanels.push(2)
    }
  }
}
</script>
<template>
  <v-card class="advanced-search--container" :class="{ 'advanced-search--floating': isMobile }" @click:outside="close">
    <div class="advanced-search--mobile-header" v-if="isMobile">
      <h2 class="advanced-search--sub-title bolder h6">{{ $t('advanced.search:header.title') }}</h2>
      <v-btn text @click="$emit('close')"><v-icon>mdi-close</v-icon></v-btn>
    </div>
    <div class="h-divider small" v-if="isMobile"></div>
    <input-field v-if="!isMobile" v-model="formData.textualSearch" class="advanced-search--text-search" outlined
      :placeholder="$t('advanced.search:text.search.placeholder')" append-icon="mdi-magnify"
      @click:append="search(false)" @click:clear="clearTextualSearch" @keydown.enter="search(false)" hideValidation
      clearable></input-field>
    <accordion multiple v-model="openPanels">
      <accordion-item titleClass="h8" :title="$t('advanced.search:categories.title')">
        <v-checkbox v-for="category in availableCategories" class="body--1" v-model="formData.shouldWatch.categories"
          :key="category.value" :label="category.text" hide-details="auto" :value="category.value" :color="getPrimaryColor"></v-checkbox>
      </accordion-item>
      <accordion-item titleClass="h8" :title="$t('advanced.search:skills.title')">
        <autocomplete v-model="formData.shouldWatch.skills" chips deletable-chips :items="availableSkills"
          :item-value="item => item" item-text="title" :placeholder="$t('advanced.search:skill.input.placeholder')"
          :no-data-text="$t('global:no.data.text')" :search-input.sync="searchSkill" multiple outlined small
          hideValidation primary clearable @change="searchSkill = ''"></autocomplete>
      </accordion-item>
      <accordion-item titleClass="h8" :title="$t('advanced.search:language.title')">
        <autocomplete v-model="formData.shouldWatch.languages" chips deletable-chips
          :placeholder="$t('advanced.search:language.input.placeholder')" :no-data-text="$t('global:no.data.text')"
          :items="availableLanguages" item-text="text" item-value="value" hideValidation multiple outlined primary small
          clearable></autocomplete>
      </accordion-item>
      <div class="h-divider"></div>

      <h2 class="advanced-search--sub-title h7">{{ $t('advanced.search:section.content.and.list.title') }}</h2>

      <accordion-item titleClass="h8" :title="$t('advanced.search:types.title')">
        <v-checkbox v-for="category in contentTypes" class="body--1" v-model="formData.shouldWatch.contentTypes"
          :key="category.alias" :label="$t(`content.type:${category.alias}`)" hide-details="auto"
          :value="category.id" :color="getPrimaryColor"></v-checkbox>
      </accordion-item>
      <accordion-item titleClass="h8" :title="$t('advanced.search:level.title')">
        <v-checkbox v-for="category in contentLevels" class="body--1" v-model="formData.shouldWatch.levels"
          :key="category" :label="$t(`skill.modal.levels:${category}`)" hide-details="auto"
          :value="category" :color="getPrimaryColor"></v-checkbox>
      </accordion-item>
      <accordion-item titleClass="h8" :title="$t('advanced.search:duration.title')">
        <div class="advanced-search--duration-time">
          <input-field class="body--1" v-model="formData.duration.from" @keypress="isNumber($event)"
            :placeholder="$t('global:time.from', { num: 15 })" outlined small primary hideValidation
            clearable></input-field>
          <input-field class="body--1" v-model="formData.duration.to" :placeholder="$t('global:time.to', { num: 20 })"
            outlined small primary hideValidation clearable @keypress="isNumber($event)"></input-field>
        </div>
        <div class="advanced-search--duration-type">
          <select-field :items="durationType" class="settings-language--select" v-model="formData.duration.type"
            hideValidation outlined small primary></select-field>
          <v-btn color="#1200D3" @click="search()"><v-icon size="24px">mdi-check</v-icon></v-btn>
        </div>
      </accordion-item>
    </accordion>
    <transition name="slide-bottom-top">
      <div class="advanced-search--mobile-actions" v-if="mounted && isMobile">
        <v-btn class="mobile-action-btn" @click="clearFilter()" outlined color="#1200D3" text>Limpar</v-btn>
        <v-btn class="mobile-action-btn" @click="search()" color="#1200D3">Aplicar</v-btn>
      </div>
    </transition>
  </v-card>
</template>
<style lang="scss">
.advanced-search--container {
  /* z-index: 999999 !important; */
  padding: 20px 16px;

  .v-input.body--1 {
    color: $primary-medium !important;
    caret-color: $primary-medium !important;
  }

  .advanced-search--text-search {
    margin-bottom: 20px;

    .v-input__slot,
    .v-input__control,
    .v-input {
      height: 40px;
      min-height: 40px;

      &.v-input--is-focused {
        .v-input__append-inner:nth-last-child(1) {
          .v-icon {
            color: $neutral-white !important;
          }
        }
      }
    }

    .v-input__append-inner {
      margin-top: 8px;
      padding-right: 4px;
    }

    .v-input__append-inner:nth-last-child(1) {
      background: $primary-medium;
      margin: 0 -12px 0 0;
      border-radius: 0 4px 4px 0;
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      .v-icon {
        color: $neutral-white;
      }
    }
  }

  .advanced-search--sub-title {
    margin: 0 auto 20px 0;
    font-size: 21px;
  }

  .advanced-search--duration-time,
  .advanced-search--duration-type {
    display: flex;
  }

  .advanced-search--duration-time {
    margin-top: 5px;

    > :nth-child(1) {
      margin-right: 5px;
    }
  }

  .advanced-search--duration-type {
    margin-top: 8px;

    .v-btn {
      height: 40px;
      width: 40px;
      min-width: 40px;
      margin-left: 10px;

      .v-icon {
        font-size: 30px;
        color: white
      }
    }
  }

  &.advanced-search--floating.v-card {
    position: fixed;
    height: 100%;
    max-width: 90%;
    overflow: auto;
    z-index: 115;
    top: 0;
    right: 0;
    box-shadow: 0 2px 20px 0 black;
    padding-bottom: 60px;

    .advanced-search--mobile-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .v-btn {
        padding: 0;
        min-width: 32px;
      }

      .advanced-search--sub-title {
        margin-bottom: 5px;
      }
    }

    .advanced-search--mobile-actions {
      position: fixed;
      bottom: 0;
      right: 0;
      width: 90%;
      z-index: 110;
      background: #FFFFFF;
      height: 56px;

      .mobile-action-btn.v-btn {
        @extend .bolder;
        height: 100%;
        width: 50%;
        border-radius: 0;

        &:nth-last-child(1) {
          color: $neutral-white;
        }
      }
    }
  }
}

.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: 25px !important;
}

@media only screen and (max-width: 768px) {
  .v-pagination__more {
    display: none;
  }

  .advanced-search--duration-type {
  justify-content: space-between;
  margin-bottom: 60px;
  }

  .advanced-search--container{
    /* z-index: 9999999 !important; */
  }
}
</style>
