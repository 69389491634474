<script>
import DiscoveryList from '@/components/discovery/DiscoveryList'
import SelectField from '@/components/general/SelectField'
import Pagination from '@/components/general/Pagination'
import AdvancedSearch from '@/components/discovery/AdvancedSearch'
import AdvancedSearchMobile from '@/components/discovery/AdvancedSearchMobile'
import AppliedFilters from '@/components/discovery/AppliedFilters'
import Breadcrumbs from '@/components/general/Breadcrumbs'
import ModalContentListAssociationVue from '../components/contents/ModalContentListAssociation.vue'
import ErrorList from '@/components/general/ErrorList'
import Loading from '@/components/general/Loading'
export default {
  name: 'Discovery',
  components: {
    Pagination,
    SelectField,
    DiscoveryList,
    AppliedFilters,
    AdvancedSearch,
    AdvancedSearchMobile,
    Breadcrumbs,
    ErrorList,
    Loading,
    ModalContentListAssociationVue
  },
  data () {
    return {
      showAdvancedSearch: false,
      showAddList: false,
      contentId: '',
      pagination: {
        page: 1,
        sortBy: 'popular',
        filter: {},
        totalPages: 1,
        totalItems: -1
      },
      filterOptions: [
        { value: 'popular', text: this.$t('global.filter:most.popular') },
        { value: 'recent', text: this.$t('global.filter:most.recent') }
      ],
      discoveryList: [],
      getDiscoveryPagination: null,
      errorState: false,
      loading: true
    }
  },
  computed: {
    breadcrumbsPaths () {
      const path = [{ text: 'main.header:discovery', href: this.$route.path }]
      this.isSearching && path.push({ text: 'global:search', href: this.$route.path })
      return path
    }
  },
  watch: {
    'pagination.page' (page) {
      this.$router.push({ name: 'business.discovery.index', query: { page } })
    },
    'pagination.sortBy' () {
      this.pagination.page = 1
      this.fetchPage()
    },
    $route: {
      handler: function (to, from) {
        if (this.$route.name === 'business.discovery.index' && !this.$route.meta.backRouteName) {
          if (this.$route.query.page) {
            this.pagination.page = parseInt(this.$route.query.page)
            this.fetchPage(true)
          } else {
            if (from.name === 'business.discovery.index.lists.details' || from.name === 'business.discovery.index.content.details') {
              this.$router.push({ name: 'business.discovery.index' }).catch(() => {})
            } else {
              this.$router.push({ name: 'business.discovery.index', query: { page: this.pagination.page } })
            }
          }
        }
      }
    }
  },
  methods: {
    fetchPage (preventScroll = false) {
      this.debounceEvent(() => {
        const payload = this.cloneObj(this.pagination)
        if (payload.filter.skills) {
          payload.filter.skills = payload.filter.skills.map(s => s.id)
        }
        this.$store.dispatch('attemptGetDiscoveryList', payload)
          .then((data) => {
            if (data && data.error) {
              this.errorState = true
              this.loading = false
              return
            }
            !preventScroll && this.smoothScrollTop()
            this.discoveryList = data.data
            this.pagination.totalPages = data.totalPages
            this.pagination.totalItems = data.total
          })
          .finally(() => {
            this.getDiscoveryPagination = this.pagination
            this.$store.dispatch('attemptSetShowSpinner', false)
            this.loading = false
          })
      }, 20)
    },
    applyFilter (searchParams) {
      this.pagination.page = 1
      this.pagination.filter = searchParams
      this.fetchPage()
    },
    showModalList (params) {
      this.contentId = params
      this.showAddList = !this.showAddList
    }
  },
  beforeRouteLeave (_, __, next) {
    this.$store.dispatch('attemptSetShouldCachingFilters', false)
    next()
  },
  created () {
    this.$store.dispatch('attemptSetShowSpinner', true)
    this.fetchPage()
    if (this.getDiscoveryPagination) {
      this.pagination = this.getDiscoveryPagination
    }
  }
}
</script>

<template>
  <div class="discovery--container center-small">
    <div class="default-header">
      <breadcrumbs :items="breadcrumbsPaths" />
      <h2>{{ $t('discovery.filter:title.business') }}</h2>
    </div>
    <loading v-if="loading"></loading>
    <error-list :title="this.$t('empty.state.text.published:error.text')" v-else-if="errorState && !loading"></error-list>
    <div v-else class="discovery--main-container">
      <div class="discovery--filter-container">
        <advanced-search-mobile v-model="pagination.filter" @search="applyFilter"
          @toggleFilter="showAdvancedSearch = !showAdvancedSearch" v-if="isMobile"></advanced-search-mobile>
        <transition name="slide-right">
          <advanced-search v-model="pagination.filter" @search="applyFilter" v-if="!isMobile || showAdvancedSearch"
            @close="showAdvancedSearch = false"></advanced-search>
        </transition>
      </div>
      <div class="discovery--body">
        <div class="discovery--body-actions">
          <applied-filters :key="pagination.totalItems" :totalItems="pagination.totalItems" :filters="pagination.filter"
            @applyFilter="applyFilter"></applied-filters>
          <select-field v-model="pagination.sortBy" outlined hide-details="auto" hideValidation :items="filterOptions"
            item-value="value" item-text="text"></select-field>
        </div>
        <discovery-list :list="discoveryList" @showModalAddListContent="params => showModalList(params)"
          @showModalContentDetails="params => showModalContentDetails(params)"></discovery-list>
        <modal-content-list-association-vue v-if="showAddList" @hideModalAddListContent="showModalList()"
          :contentIdProp="contentId"></modal-content-list-association-vue>
        <pagination :pagination="pagination"></pagination>
      </div>
    </div>
    <router-view />
  </div>
</template>

<style lang="scss">
.discovery--container {
  .discovery--main-container {
    display: flex;
    position: relative;
    overflow-y: auto;
    width: 100vw;
    left: calc(-50vw + 50%);
    padding-left: 24px;
    padding-right: 24px;

    .discovery--body-actions {
      display: flex;
      justify-content: space-between;
      padding-right: 4px;
      padding-left: 4px;

      .input-field--wrapper {
        max-width: 200px;
      }

      .v-text-field--outlined>.v-input__control>.v-input__slot {
        min-height: 40px;
      }

      .v-text-field--enclosed .v-input__append-inner {
        margin-top: 12px;
      }
    }

    .discovery--filter-container {
      width: 300px;
    }

    .discovery--body {
      width: calc(100% - 300px);
      padding: 0 0 40px 40px;
    }
  }

  @media only screen and (max-width: 800px) {
    flex-direction: column;
    padding-bottom: 140px;

    .discovery--main-container {
      display: block;

      .discovery--filter-container {
        width: 100%;
      }

      .discovery--body {
        width: 100%;
        padding: 0;
      }

      .discovery--body-actions {
        flex-direction: column;
        max-width: 100%;
        padding: 8px 0;
        margin: 0 auto;

        .select-field{
          max-width: 100%;
        }
      }
    }
  }
}
</style>
